var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('ValidationObserver',{ref:"form"},[_c('a-form',{ref:"aform",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('a-form-item',{attrs:{"label":"Кабинет"}},[_c('a-select',{attrs:{"default-value":"0"},model:{value:(_vm.cabinet),callback:function ($$v) {_vm.cabinet=$$v},expression:"cabinet"}},[_c('a-select-option',{attrs:{"value":0}},[_vm._v("Все")]),_c('a-select-option',{attrs:{"value":1}},[_vm._v("Дилер")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("Поставщик")])],1)],1),_c('a-form-item',{attrs:{"label":"Тема новости *"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-input',{attrs:{"required":""},model:{value:(_vm.item.subject),callback:function ($$v) {_vm.$set(_vm.item, "subject", $$v)},expression:"item.subject"}}),_vm._l((errors),function(error){return _c('a-alert',{key:error,attrs:{"type":"error","message":error,"banner":""}})})]}}])})],1),_c('a-form-item',{attrs:{"label":"Краткое описание *"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-textarea',{attrs:{"required":""},model:{value:(_vm.item.announcement),callback:function ($$v) {_vm.$set(_vm.item, "announcement", $$v)},expression:"item.announcement"}}),_vm._l((errors),function(error){return _c('a-alert',{key:error,attrs:{"type":"error","message":error,"banner":""}})})]}}])})],1),_c('a-form-item',{attrs:{"label":"Текст новости *"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-textarea',{model:{value:(_vm.item.content),callback:function ($$v) {_vm.$set(_vm.item, "content", $$v)},expression:"item.content"}}),_vm._l((errors),function(error){return _c('a-alert',{key:error,attrs:{"type":"error","message":error,"banner":""}})})]}}])})],1),_c('a-form-item',{attrs:{"label":"Изображение для новости *"}},[_c('ValidationProvider',{attrs:{"rules":"image","name":"imagecount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-upload',{attrs:{"file-list":_vm.fileList,"remove":_vm.handleRemove,"before-upload":_vm.beforeUpload,"multiple":false,"list-type":"picture-card","default-file-list":_vm.defaultFileList,"withCredentials":"","accept":"image/*"},model:{value:(_vm.imagecount),callback:function ($$v) {_vm.imagecount=$$v},expression:"imagecount"}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Выбрать ")],1)],1),_vm._l((errors),function(error){return _c('a-alert',{key:error,attrs:{"type":"error","message":error,"banner":""}})})]}}])})],1),_c('a-form-item',{attrs:{"label":"Документ"}},[_c('ValidationProvider',{attrs:{"rules":"file","name":"filescount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-upload',{attrs:{"file-list":_vm.fileList2,"remove":_vm.handleRemove2,"before-upload":_vm.beforeUpload2,"multiple":false,"list-type":"text","default-file-list":_vm.defaultFileList2,"withCredentials":"","accept":".pdf,.doc,.docx,.xls,.xlsx"},model:{value:(_vm.filescount),callback:function ($$v) {_vm.filescount=$$v},expression:"filescount"}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Выбрать ")],1)],1),_vm._l((errors),function(error){return _c('a-alert',{key:error,attrs:{"type":"error","message":error,"banner":""}})})]}}])})],1),_c('div',[(_vm.isManufacturerAdmin)?_c('div',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('a-button',{attrs:{"color":"red","type":"submit"},on:{"click":function($event){return _vm.save()}}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" Сохранить ")],1),_c('a-button',{staticClass:"ml-6",on:{"click":function($event){return _vm.$router.push({ name: 'News' })}}},[_c('a-icon',{attrs:{"type":"close"}}),_vm._v(" Отменить ")],1)],1):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }