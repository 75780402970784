<template>
  <div>
    <a-spin :spinning="spinning">
      <ValidationObserver ref="form">
        <a-form ref="aform" @submit.prevent="save">
          <a-form-item label="Кабинет">
            <a-select default-value="0" v-model="cabinet">
              <a-select-option :value="0">Все</a-select-option>
              <a-select-option :value="1">Дилер</a-select-option>
              <a-select-option :value="2">Поставщик</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Тема новости *">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <a-input v-model="item.subject" required />
              <a-alert
                v-for="error of errors"
                :key="error"
                type="error"
                :message="error"
                banner
              />
            </ValidationProvider>
          </a-form-item>
          <a-form-item label="Краткое описание *">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <a-textarea required v-model="item.announcement" />
              <a-alert
                v-for="error of errors"
                :key="error"
                type="error"
                :message="error"
                banner
              />
            </ValidationProvider>
          </a-form-item>
          <a-form-item label="Текст новости *">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <a-textarea v-model="item.content" />
              <a-alert
                v-for="error of errors"
                :key="error"
                type="error"
                :message="error"
                banner
              />
            </ValidationProvider>
          </a-form-item>
          <a-form-item label="Изображение для новости *">
            <ValidationProvider
              rules="image"
              name="imagecount"
              v-slot="{ errors }"
            >
              <a-upload
                v-model="imagecount"
                :file-list="fileList"
                :remove="handleRemove"
                :before-upload="beforeUpload"
                :multiple="false"
                list-type="picture-card"
                :default-file-list="defaultFileList"
                withCredentials
                accept="image/*"
              >
                <a-button>
                  <a-icon type="upload" />
                  Выбрать
                </a-button>
              </a-upload>
              <a-alert
                v-for="error of errors"
                :key="error"
                type="error"
                :message="error"
                banner
              />
            </ValidationProvider>
          </a-form-item>
          <a-form-item label="Документ">
            <ValidationProvider
              rules="file"
              name="filescount"
              v-slot="{ errors }"
            >
              <a-upload
                v-model="filescount"
                :file-list="fileList2"
                :remove="handleRemove2"
                :before-upload="beforeUpload2"
                :multiple="false"
                list-type="text"
                :default-file-list="defaultFileList2"
                withCredentials
                accept=".pdf,.doc,.docx,.xls,.xlsx"
              >
                <a-button>
                  <a-icon type="upload" />
                  Выбрать
                </a-button>
              </a-upload>
              <a-alert
                v-for="error of errors"
                :key="error"
                type="error"
                :message="error"
                banner
              />
            </ValidationProvider>
          </a-form-item>
          <div>
            <div cols="6" class="d-flex" v-if="isManufacturerAdmin">
              <a-button color="red" type="submit" @click="save()">
                <a-icon type="save" />
                Сохранить
              </a-button>
              <a-button class="ml-6" @click="$router.push({ name: 'News' })">
                <a-icon type="close" />
                Отменить
              </a-button>
            </div>
          </div>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { extend } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import { mapActions, mapGetters } from "vuex"
import { message } from "ant-design-vue"
extend("required", {
  ...required,
  message: "Обязательно для заполнения",
})
extend("image", {
  validate: val => {
    return parseInt(val) === 1
  },
  message: "Необходимо выбрать одно изображение",
})
extend("file", {
  validate: val => {
    return parseInt(val) < 2
  },
  message: "Можно загрузить только один файл",
})
const item = {
  subject: "",
  announcement: "",
  content: "",
  is_published: 1,
  is_important: 0,
  is_limited_company_types: 0,
  is_active: 1,
  company_types: [],
}
export default {
  name: "NewsEdit",
  props: {
    news: {
      type: Object,
      default: () => {
        return { ...item }
      },
    },
  },
  data() {
    return {
      item: item,
      fileList: [],
      fileList2: [],
      defaultFileList: [],
      defaultFileList2: [],
      cabinet: 0,
      spinning: false,
      deletedImages: [],
      deletedFiles: [],
    }
  },
  computed: {
    ...mapGetters({
      isManufacturer: "auth/isManufacturer",
      isManufacturerAdmin: "auth/isManufacturerAdmin",
      isDealer: "auth/isDealer",
    }),
    imagecount() {
      return this.fileList.length + this.defaultFileList.length
    },
    filescount() {
      return this.fileList2.length + this.defaultFileList2.length
    },
  },
  methods: {
    ...mapActions({
      createNews: "news/createNews",
      updateNews: "news/updateCurrentNews",
    }),
    async save() {
      const valid = await this.$refs.form.validate()
      if (!valid) return
      switch (parseInt(this.cabinet)) {
        case 0:
          this.item.company_types = []
          this.item.is_limited_company_types = 0
          break
        case 1:
          this.item.company_types = [0]
          this.item.is_limited_company_types = 1
          break
        case 2:
          this.item.company_types = [1]
          this.item.is_limited_company_types = 1
          break

        default:
          break
      }
      const formData = new FormData()
      for (const key in this.item) {
        if (
          Object.hasOwnProperty.call(this.item, key) &&
          key != "company_types"
        ) {
          formData.append(key, this.item[key])
        }
      }
      for (const iterator of this.item.company_types) {
        formData.append("company_types[]", iterator)
      }
      for (const iterator of this.deletedFiles) {
        formData.append("deleted_files[]", iterator)
      }
      for (const iterator of this.deletedImages) {
        formData.append("deleted_images[]", iterator)
      }
      this.fileList.forEach(file => {
        if (file instanceof File) formData.append("image", file)
      })
      this.fileList2.forEach(file => {
        if (file instanceof File) formData.append("file", file)
      })
      try {
        if (!this.item.id) {
          this.spinning = true
          const n = await this.createNews(formData)
          message.success("Сохранено")
          this.$router.push({ name: "NewsEdit", params: { id: n.id } })
        } else {
          this.spinning = true
          await this.updateNews({ id: this.item.id, news: formData })
          message.success("Сохранено")
        }
      } catch (error) {
        this.spinning = false
        message.error(error.message)
      }
      this.spinning = false
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      if (!(this.fileList[index] instanceof File)) {
        this.deletedImages.push(this.fileList[index].uid)
      }
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },

    beforeUpload(file) {
      this.fileList = [...(this.fileList || []), file]
      return false
    },
    handleRemove2(file) {
      const index = this.fileList2.indexOf(file)
      if (!(this.fileList2[index] instanceof File)) {
        this.deletedFiles.push(this.fileList2[index].uid)
      }
      const newFileList = this.fileList2.slice()
      newFileList.splice(index, 1)
      this.fileList2 = newFileList
    },

    beforeUpload2(file) {
      this.fileList2 = [...(this.fileList2 || []), file]
      return false
    },
    async remote(clojure) {
      try {
        await clojure()
      } catch (e) {
        message.error(e)
      }
    },
    parseItem(val) {
      const resp = { ...val }
      const tmp = { ...this.news }

      tmp.company_types = resp.company_types
      if (!tmp.is_limited_company_types) {
        this.cabinet = 0
        tmp.company_types = []
      } else {
        this.cabinet = tmp.for_company_types.filter(
          el => parseInt(el.type) === 1
        ).length
          ? 2
          : 1
      }
      if (resp.image) {
        this.fileList = [
          {
            uid: resp.image.uuid,
            name: resp.image.name,
            status: "done",
            response: "",
            url: resp.image.original_url,
          },
        ]
      }
      if (resp.file) {
        this.fileList2 = [
          {
            uid: resp.file.uuid,
            name: resp.file.name,
            status: "done",
            response: "",
            url: resp.file.original_url,
          },
        ]
      }
      delete tmp.image
      delete tmp.file
      delete tmp.start_date
      delete tmp.end_date
      this.deletedFiles = []
      this.deletedImages = []
      this.item = tmp
    },
  },
  mounted() {
    this.$refs.form.reset()
    const l = async () => {
      if (!this.$route.params.id) return
      this.spinning = true
      await this.$store.dispatch("news/getNews", this.$route.params.id)
      this.spinning = false
    }
    l()
  },
  watch: {
    news(value) {
      this.parseItem(value)
    },
  },
}
</script>

<style scoped>
.ant-input {
  color: #05141f;
}
</style>
